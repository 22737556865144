body {
  background-color: #fafafa;
}

/* TODO: Work out how to conditionally load fonts only for the active branding */

/* Enroller branding */
@font-face {
  font-family: 'Inter';
  src: local('Inter'), url('../assets/fonts/Inter-Regular.ttf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'Inter';
  src: local('Inter'), url('../assets/fonts/Inter-Medium.ttf') format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'Inter';
  src: local('Inter'), url('../assets/fonts/Inter-SemiBold.ttf') format('truetype');
  font-weight: 600;
}

/* Educatius branding */
@font-face {
  font-family: 'TheFuture';
  src: local('TheFuture'), url('../assets/fonts/TheFuture-Regular.otf') format('opentype');
  font-weight: 400;
}
@font-face {
  font-family: 'TheFuture';
  src: local('TheFuture'), url('../assets/fonts/TheFuture-Medium.otf') format('opentype');
  font-weight: 500;
}
@font-face {
  font-family: 'TheFuture';
  src: local('TheFuture'), url('../assets/fonts/TheFuture-Black.otf') format('opentype');
  font-weight: 600;
}
@font-face {
  font-family: 'TheFuture';
  src: local('TheFuture'), url('../assets/fonts/TheFuture-Bold.otf') format('opentype');
  font-weight: 700;
}

/* Greenheart branding */
@font-face {
  font-family: 'Lato';
  src: local('Lato'), url('../assets/fonts/Lato-Light.ttf') format('truetype');
  font-weight: 300;
}
@font-face {
  font-family: 'Lato';
  src: local('Lato'), url('../assets/fonts/Lato-LightItalic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: 'Lato';
  src: local('Lato'), url('../assets/fonts/Lato-Regular.ttf') format('truetype');
  font-weight: 400;
}
@font-face {
  font-family: 'Lato';
  src: local('Lato'), url('../assets/fonts/Lato-Italic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: 'Lato';
  src: local('Lato'), url('../assets/fonts/Lato-Bold.ttf') format('truetype');
  font-weight: 700;
}
@font-face {
  font-family: 'Lato';
  src: local('Lato'), url('../assets/fonts/Lato-BoldItalic.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
}
